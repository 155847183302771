import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faInstagram,
  faLinkedin,
  faGooglePlusSquare,
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaLinks = () => {
  return (
    <ul className="ml-1 mt-8 flex items-center" aria-label="Social Media">
            <li className="mr-5 text-xs">
              <a
                href="https://github.com/Ana-teston"
                className="block  hover:text-slate-300"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">GitHub</span>
                <FontAwesomeIcon
                  icon={faGithub}
                  className="h-6 w-6 text-slate-300 hover:text-slate-200"
                />
              </a>
            </li>
            <li className="mr-5 text-xs">
              <a
                href="https://www.linkedin.com/in/anateston/"
                className="block  hover:text-slate-300"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Linkedin</span>
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="h-6 w-6 text-slate-300 hover:text-slate-200"
                />
              </a>
            </li>
            <li className="mr-5 text-xs">
              <a
                href="https://www.instagram.com/paulateston/"
                className="block  hover:text-slate-300"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">Instagram</span>
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="h-6 w-6 text-slate-300 hover:text-slate-200"
                />
              </a>
            </li>
            <li className="mr-5 text-xs">
              <a
                href="https://app.enhancv.com/share/987c861a/?utm_medium=growth&utm_campaign=share-resume&utm_source=dynamic"
                className="block  hover:text-slate-300"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">CV and Mail</span>
                <FontAwesomeIcon
                  icon={faGooglePlusSquare}
                  className="h-6 w-6 text-slate-300 hover:text-slate-200"
                />
              </a>
            </li>
          </ul>
  )
};

export default SocialMediaLinks;
